
.link
{
    cursor: pointer;
    margin-bottom: 10px;
    color: var(--font);
    text-decoration: none;
    background: var(--principal);
    border: none;
    border-radius: 4px;
    padding: 5px 12px;
    font-family: 'Nunito';
    width: auto;
}

.link:hover
{
    transform: scale(1.05);
    transition: .2s ease all;
}