
nav
{
    color: var(--font);
    display: flex;
    justify-content: space-between;
    padding: 0 5%;
    background-color: var(--fondo2);
    height: 70px;
    align-items: center;
}

.titulo-nav
{
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}

.container-btn
{
    display: flex;
    gap: 2%;
}

.btn-nav-secundarios
{
    background-color: transparent;
    border: none;
    color: var(--font);
    width: 100px;
    height: 35px;
    cursor: pointer;
    font-family: 'Nunito';
    font-size: 16px;
}

.btn-nav-principal
{
    border: none;
    color: var(--font);
    background-color: var(--principal);
    width: 120px;
    border-radius: 20px;
    height: 35px;
    cursor: pointer;
    font-family: 'Nunito';
    font-size: 16px;
}

.btn-nav-secundarios:hover
{
    background-color: #4834d44f;
    border-radius: 50px;
    transition: .3s ease all;
}

.btn-nav-principal:hover
{
    transform: scale(1.1);
    transition: .2s ease all;
}

@media screen and (max-width: 760px)
{
	nav
	{
		justify-content: center;
	}
}

@media screen and (max-width: 476px)
{
    .btn-nav-secundarios, .btn-nav-principal
    {
        font-size: 13px;
        width: 100px;
    }    
}

@media screen and (max-width: 415px)
{
    .btn-nav-secundarios, .btn-nav-principal
    {
        width: 80px;
    }

    .btn-nav-principal
    {
        padding: 1% 0;
    }
}
