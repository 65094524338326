.container-sistema
{
    height: calc(100%);
    text-align: center;
    position: relative;
}

.container-sistema h3
{
    margin: 0;
}

.container-titulo-sist
{
    padding-top: 5%;
}

.container-links-sist
{
    text-align: center;
    border-right: 3px solid white;
    width: 120px;
    padding-right: 10px;
}

.container-img-logo-sist
{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3%;
}

.container-img-logo-sist img
{
    max-width: 150px;
}

.container-info-sist
{
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
}

.img-sist-app
{
    max-height: 305px;  
    border-radius: 5px;
    box-shadow: 0px 0px 50px 0px #161616bf;
}

.num-1
{
    border-top-left-radius: 5px;
    background-color: #686de0;
}

.num-2
{
    border-top-right-radius: 5px;
    background-color: #30336b;
}

.num-3
{
    border-bottom-left-radius: 5px;
    background-color: #4834d4;
}

.num-4
{
    border-bottom-right-radius: 5px;
    background-color: #130f40;
}

.capa
{
    position: absolute;
    top: 0;
    width: 90%;
    height: 100%;
    padding: 0 5%;
    background: #171046c2;
    transition: all 400ms ease-out;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center
}

.container-sistema:hover > .capa 
{
    opacity: 1;
    visibility: visible;
}

.container-sistema:hover > .capa h3
{
    margin-top: 70px;
    margin-bottom: 15px;
}

.container-sistema .capa h3
{
    color: #fff;
    font-weight: 400;
    margin-bottom: 120px;
    transition: all 400ms ease-out;
    margin-top: 30px;
}

.container-sistema .capa p
{
    color: #fff;
    font-size: 15px;
    line-height: 1.5;
    width: 100%;
    max-width: 220px;
    margin: auto;
}
