.container-habilidades-info
{
    text-align: center;
    margin-top: 30px;
}

.container-header-habilidades, .container-habilidades-por-aprender
{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 60px;
    margin-bottom: 50px;
}

.container-header-habilidades img, .container-habilidades-por-aprender img
{
	object-fit: contain;
    width: 140px;
}

.container-body-habilidades
{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15% 10%;
}

@media screen and (max-width: 710px)
{
    .container-header-habilidades img
    {
        object-fit: contain;
        width: 100px;
    }
}

@media screen and (max-width: 610px)
{
    .container-max
    {
        max-width: 300px;
    }

    .container-header-habilidades, .container-habilidades-por-aprender
    {
        
        margin: 0;
    }

    .container-header-habilidades img
    {
        width: 70px;
    }

    .container-habilidades
    {
        height: 190px;
    }
}