@import url(https://fonts.googleapis.com/css?family=Nunito);
:root
{
    --fondo2: #130f40;
    --principal: #4834d4;
    --secundario: #eb4d4b;
    --font: #dff9fb;
    --font2: #535c68;
    --rojo: #ff7979;
    --verde: #badc58;
    --delay: 0s;
}

body 
{
    margin: 0;
    font-family: 'Nunito';
    color: #130f40;
    color: var(--fondo2);
    width: 100%;
    height: 100vh; 
}

header
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 20px;
}

header h3
{
    margin: 0;
}

.container-app
{
    display: flex;
    justify-content: center;
    height: calc(100vh - 70px);
    align-items: center;
}

.container-info-sistem
{
    position: absolute;
    color: white;
    display: flex;
    background-color: #130f40cf;
}

.container-bienvenida
{
	max-width: 972px;
}

.container-info
{
    display: flex;
    justify-content: center;
}

.container-max
{
    margin-top: 100px;
    max-width: 972px;
}

.container-scroll-mouse
{
    position: relative;
    text-align: center;
    bottom: -130px;
}

.container-mouse
{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.container-redes
{
    display: flex;
    justify-content: center;
    grid-gap: 49px;
    gap: 49px;
}

.container-texto-bienvenida
{
	margin-bottom: 20px;
}

.container-img-bienvenida
{
	display: flex;
}

.container-text-img
{
	display: flex;
	align-items: end;
}

h1
{
    font-size: 50px;
    color: #4834d4;
    color: var(--principal);
}

h2
{
    margin: 0;
    font-size: 40px;
    color: #4834d4;
    color: var(--principal);
    text-align: center;
}

h3
{
    color: #dff9fb;
    color: var(--font);
    margin-top: 0;
	margin-bottom: 19px;
}

p
{
    font-size: 26px;
}

.img-mi-foto
{
    border-radius: 50%;
    border: 7px solid #4834d4;
    border: 7px solid var(--principal);
    /* position: absolute; */
    min-width: 200px;
	height: 200px;
}

.btn-mas-info-sist
{
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.titulo-bienvenida
{
    width: 590px;
    -webkit-animation: escribir 2s steps(26), parpadeo .5s step-end infinite alternate;
            animation: escribir 2s steps(26), parpadeo .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
}

.scroll-text
{
	color: #535c68;
	color: var(--font2);
	-webkit-animation: colorText2 5s ease-out infinite, nudgeText 5s ease-out infinite;
	        animation: colorText2 5s ease-out infinite, nudgeText 5s ease-out infinite;
}

.mouse 
{
	background: #4834d4 linear-gradient(transparent 0%, transparent 50%, #fff 50%, #fff 100%);
	background: var(--principal) linear-gradient(transparent 0%, transparent 50%, #fff 50%, #fff 100%);
	position: relative;
	width: 52px;
	height: 88px;
	border-radius: 100px;
	background-size: 100% 200%;
	-webkit-animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
	        animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
}

.mouse:before, .mouse:after 
{
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
}

.mouse:before 
{
	width: 46px;
	height: 82px;
	background-color: white;
	border-radius: 100px;
}

.mouse:after 
{
	background-color: #4834d4;
	background-color: var(--principal);
	width: 10px;
	height: 10px; 
	border-radius: 100%;
	-webkit-animation: trackBallSlide 5s linear infinite;
	        animation: trackBallSlide 5s linear infinite;
}

.link-cont:hover
{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: .2s ease all;
}

.text-bienvenida
{
	margin: 0;
}

@-webkit-keyframes top 
{
    0% 
    {
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
        opacity: 0;
    }
    
    100% 
    {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}

@keyframes top 
{
    0% 
    {
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
        opacity: 0;
    }
    
    100% 
    {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}

@-webkit-keyframes escribir 
{
    from 
    {
        width: 0;
    }
}

@keyframes escribir 
{
    from 
    {
        width: 0;
    }
}
      
@-webkit-keyframes parpadeo 
{
    50% 
    {
      border-color: transparent;
    }
}
      
@keyframes parpadeo 
{
    50% 
    {
      border-color: transparent;
    }
}

@-webkit-keyframes colorSlide 
{
	0% 
    {
		background-position: 0% 100%;
	}

	20% 
    {
		background-position: 0% 0%;
	}

	21% 
    {
		background-color: #4834d4;
		background-color: var(--principal);
        
	}

	29.99% 
    {
		background-color: #fff;
		background-position: 0% 0%;
	}

	30% 
    {
		background-color: #4834d4;
		background-color: var(--principal);
		background-position: 0% 100%;
	}

	50% 
    {
		background-position: 0% 0%;
	}

	51% 
    {
		background-color: #4834d4;
		background-color: var(--principal);
	}

	59% 
    {
		background-color: #fff;
		background-position: 0% 0%;
	}
	 
    60% 
    {
		background-color: #4834d4;
		background-color: var(--principal);
		background-position: 0% 100%;
	}

	80% 
    {
		background-position: 0% 0%;
	}

	81% 
    {
		background-color: #4834d4;
		background-color: var(--principal);
	}

	90%, 100% 
    {
		background-color: #fff;
	}
}

@keyframes colorSlide 
{
	0% 
    {
		background-position: 0% 100%;
	}

	20% 
    {
		background-position: 0% 0%;
	}

	21% 
    {
		background-color: #4834d4;
		background-color: var(--principal);
        
	}

	29.99% 
    {
		background-color: #fff;
		background-position: 0% 0%;
	}

	30% 
    {
		background-color: #4834d4;
		background-color: var(--principal);
		background-position: 0% 100%;
	}

	50% 
    {
		background-position: 0% 0%;
	}

	51% 
    {
		background-color: #4834d4;
		background-color: var(--principal);
	}

	59% 
    {
		background-color: #fff;
		background-position: 0% 0%;
	}
	 
    60% 
    {
		background-color: #4834d4;
		background-color: var(--principal);
		background-position: 0% 100%;
	}

	80% 
    {
		background-position: 0% 0%;
	}

	81% 
    {
		background-color: #4834d4;
		background-color: var(--principal);
	}

	90%, 100% 
    {
		background-color: #fff;
	}
}

@-webkit-keyframes trackBallSlide 
{
	0% 
    {
		opacity: 1;
		-webkit-transform: scale(1) translateY(-20px);
		        transform: scale(1) translateY(-20px);
	}

	6% 
    {
		opacity: 1;
		-webkit-transform: scale(0.9) translateY(5px);
		        transform: scale(0.9) translateY(5px);
	}

	14% 
    {
		opacity: 0;
		-webkit-transform: scale(0.4) translateY(40px);
		        transform: scale(0.4) translateY(40px);
	}

	15%, 19% 
    {
		opacity: 0;
		-webkit-transform: scale(0.4) translateY(-20px);
		        transform: scale(0.4) translateY(-20px);
	}

	28%, 29.99% 
    {
		opacity: 1;
		-webkit-transform: scale(1) translateY(-20px);
		        transform: scale(1) translateY(-20px);
	}

	30% 
    {
		opacity: 1;
		-webkit-transform: scale(1) translateY(-20px);
		        transform: scale(1) translateY(-20px);
	}

	36% 
    {
		opacity: 1;
		-webkit-transform: scale(0.9) translateY(5px);
		        transform: scale(0.9) translateY(5px);
	}

	44% 
    {
		opacity: 0;
		-webkit-transform: scale(0.4) translateY(40px);
		        transform: scale(0.4) translateY(40px);
	}

	45%, 49% 
    {
		opacity: 0;
		-webkit-transform: scale(0.4) translateY(-20px);
		        transform: scale(0.4) translateY(-20px);
	}

	58%, 59.99% 
    {
		opacity: 1;
		-webkit-transform: scale(1) translateY(-20px);
		        transform: scale(1) translateY(-20px);
	}

	60% 
    {
		opacity: 1;
		-webkit-transform: scale(1) translateY(-20px);
		        transform: scale(1) translateY(-20px);
	}

	66% 
    {
		opacity: 1;
		-webkit-transform: scale(0.9) translateY(5px);
		        transform: scale(0.9) translateY(5px);
	}

	74% 
    {
		opacity: 0;
		-webkit-transform: scale(0.4) translateY(40px);
		        transform: scale(0.4) translateY(40px);
	}

	75%, 79% 
    {
		opacity: 0;
		-webkit-transform: scale(0.4) translateY(-20px);
		        transform: scale(0.4) translateY(-20px);
	}

	88%, 100% 
    {
		opacity: 1;
		-webkit-transform: scale(1) translateY(-20px);
		        transform: scale(1) translateY(-20px);
	}
}

@keyframes trackBallSlide 
{
	0% 
    {
		opacity: 1;
		-webkit-transform: scale(1) translateY(-20px);
		        transform: scale(1) translateY(-20px);
	}

	6% 
    {
		opacity: 1;
		-webkit-transform: scale(0.9) translateY(5px);
		        transform: scale(0.9) translateY(5px);
	}

	14% 
    {
		opacity: 0;
		-webkit-transform: scale(0.4) translateY(40px);
		        transform: scale(0.4) translateY(40px);
	}

	15%, 19% 
    {
		opacity: 0;
		-webkit-transform: scale(0.4) translateY(-20px);
		        transform: scale(0.4) translateY(-20px);
	}

	28%, 29.99% 
    {
		opacity: 1;
		-webkit-transform: scale(1) translateY(-20px);
		        transform: scale(1) translateY(-20px);
	}

	30% 
    {
		opacity: 1;
		-webkit-transform: scale(1) translateY(-20px);
		        transform: scale(1) translateY(-20px);
	}

	36% 
    {
		opacity: 1;
		-webkit-transform: scale(0.9) translateY(5px);
		        transform: scale(0.9) translateY(5px);
	}

	44% 
    {
		opacity: 0;
		-webkit-transform: scale(0.4) translateY(40px);
		        transform: scale(0.4) translateY(40px);
	}

	45%, 49% 
    {
		opacity: 0;
		-webkit-transform: scale(0.4) translateY(-20px);
		        transform: scale(0.4) translateY(-20px);
	}

	58%, 59.99% 
    {
		opacity: 1;
		-webkit-transform: scale(1) translateY(-20px);
		        transform: scale(1) translateY(-20px);
	}

	60% 
    {
		opacity: 1;
		-webkit-transform: scale(1) translateY(-20px);
		        transform: scale(1) translateY(-20px);
	}

	66% 
    {
		opacity: 1;
		-webkit-transform: scale(0.9) translateY(5px);
		        transform: scale(0.9) translateY(5px);
	}

	74% 
    {
		opacity: 0;
		-webkit-transform: scale(0.4) translateY(40px);
		        transform: scale(0.4) translateY(40px);
	}

	75%, 79% 
    {
		opacity: 0;
		-webkit-transform: scale(0.4) translateY(-20px);
		        transform: scale(0.4) translateY(-20px);
	}

	88%, 100% 
    {
		opacity: 1;
		-webkit-transform: scale(1) translateY(-20px);
		        transform: scale(1) translateY(-20px);
	}
}
 @-webkit-keyframes nudgeMouse 
{
	0% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}

	20% 
    {
		-webkit-transform: translateY(8px);
		        transform: translateY(8px);
	}

	30% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}

	50% 
    {
		-webkit-transform: translateY(8px);
		        transform: translateY(8px);
	}

	60% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}

	80% 
    {
		-webkit-transform: translateY(8px);
		        transform: translateY(8px);
	}

	90% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}
 @keyframes nudgeMouse 
{
	0% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}

	20% 
    {
		-webkit-transform: translateY(8px);
		        transform: translateY(8px);
	}

	30% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}

	50% 
    {
		-webkit-transform: translateY(8px);
		        transform: translateY(8px);
	}

	60% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}

	80% 
    {
		-webkit-transform: translateY(8px);
		        transform: translateY(8px);
	}

	90% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@-webkit-keyframes nudgeText 
{
	0% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}

	20% 
    {
		-webkit-transform: translateY(2px);
		        transform: translateY(2px);
	}

	30% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}

	50% 
    {
		-webkit-transform: translateY(2px);
		        transform: translateY(2px);
	}

	60% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}

	80% 
    {
		-webkit-transform: translateY(2px);
		        transform: translateY(2px);
	}

	90% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes nudgeText 
{
	0% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}

	20% 
    {
		-webkit-transform: translateY(2px);
		        transform: translateY(2px);
	}

	30% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}

	50% 
    {
		-webkit-transform: translateY(2px);
		        transform: translateY(2px);
	}

	60% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}

	80% 
    {
		-webkit-transform: translateY(2px);
		        transform: translateY(2px);
	}

	90% 
    {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@-webkit-keyframes colorText 
{
	21% 
    {
		color: #4834d4;
		color: var(--principal);
	}

	30% 
    {
		color: #fff;
	}

	51% 
    {
		color: #4834d4;
		color: var(--principal);
	}

	60% 
    {
		color: #fff;
	}

	81% 
    {
		color: #4834d4;
		color: var(--principal);
	}

	90% 
    {
		color: #fff;
	}
}

@keyframes colorText 
{
	21% 
    {
		color: #4834d4;
		color: var(--principal);
	}

	30% 
    {
		color: #fff;
	}

	51% 
    {
		color: #4834d4;
		color: var(--principal);
	}

	60% 
    {
		color: #fff;
	}

	81% 
    {
		color: #4834d4;
		color: var(--principal);
	}

	90% 
    {
		color: #fff;
	}
}

@-webkit-keyframes colorText2
{
	21% 
    {
		color: #9a8fed;
	}

	30% 
    {
		color: #fff;
	}

	51% 
    {
		color: #9a8fed;
	}

	60% 
    {
		color: #fff;
	}

	81% 
    {
		color: #9a8fed;
	}

	90% 
    {
		color: #fff;
	}
}

@keyframes colorText2
{
	21% 
    {
		color: #9a8fed;
	}

	30% 
    {
		color: #fff;
	}

	51% 
    {
		color: #9a8fed;
	}

	60% 
    {
		color: #fff;
	}

	81% 
    {
		color: #9a8fed;
	}

	90% 
    {
		color: #fff;
	}
}

@-webkit-keyframes colorText3
{
	21% 
    {
		color: #12f35d;
	}

	30% 
    {
		color: #fff;
	}

	51% 
    {
		color: #12f35d;
	}

	60% 
    {
		color: #fff;
	}

	81% 
    {
		color: #12f35d;
	}

	90% 
    {
		color: #fff;
	}
}

@keyframes colorText3
{
	21% 
    {
		color: #12f35d;
	}

	30% 
    {
		color: #fff;
	}

	51% 
    {
		color: #12f35d;
	}

	60% 
    {
		color: #fff;
	}

	81% 
    {
		color: #12f35d;
	}

	90% 
    {
		color: #fff;
	}
}

@media screen and (max-width: 1024px)
{
	.container-bienvenida
	{
		max-width: 890px;
	}

	p
	{
		font-size: 23px;
	}
}

@media screen and (max-width: 939px)
{
	.container-bienvenida
	{
		max-width: 800px;
	}

	.titulo-bienvenida
	{
		font-size: 40px;
		width: 480px;
	}

	.img-mi-foto
	{
		min-width: 170px;
		height: 170px;
	}

	p
	{
		font-size: 21px;
	}
}

@media screen and (max-width: 850px)
{
	.container-bienvenida
	{
		max-width: 700px;
	}

	p
	{
		font-size: 18px;
	}

	.img-mi-foto
	{
		min-width: 150px;
		height: 150px;
	}

	.container-scroll-mouse
	{
		bottom: -80px;
	}
}

@media screen and (max-width: 760px)
{
	.container-bienvenida
	{
		max-width: 600px;
	}

	.titulo-bienvenida
	{
		font-size: 36px;
		width: 430px;
	}

	.img-mi-foto
	{
		min-width: 140px;
		height: 140px;
	}

	p
	{
		font-size: 15.5px;
	}

	.titulo-nav
	{
		display: none;
	}

}

@media screen and (max-width: 650px)
{
	.container-bienvenida
	{
		max-width: 500px;
	}

	.titulo-bienvenida
	{
		font-size: 27px;
		width: 320px;
	}

	.img-mi-foto
	{
		min-width: 120px;
		height: 120px;
		border: 5px solid #4834d4;
		border: 5px solid var(--principal);
	}
}

@media screen and (max-width: 512px)
{
	.container-bienvenida
	{
		max-width: 450px;
	}
}

@media screen and (max-width: 476px)
{
	.container-bienvenida
	{
		max-width: 320px;
	}

	.container-app
	{
		padding-top: 140px;
		align-items: baseline;
	}

	.container-img-mifoto
	{
		position: absolute;
		top: 10px;
		right: 0px;
		width: 100%;
		display: flex;
		justify-content: center
	}

	.container-scroll-mouse
	{
		bottom: -20px;
	}
}


nav
{
    color: var(--font);
    display: flex;
    justify-content: space-between;
    padding: 0 5%;
    background-color: var(--fondo2);
    height: 70px;
    align-items: center;
}

.titulo-nav
{
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}

.container-btn
{
    display: flex;
    grid-gap: 2%;
    gap: 2%;
}

.btn-nav-secundarios
{
    background-color: transparent;
    border: none;
    color: var(--font);
    width: 100px;
    height: 35px;
    cursor: pointer;
    font-family: 'Nunito';
    font-size: 16px;
}

.btn-nav-principal
{
    border: none;
    color: var(--font);
    background-color: var(--principal);
    width: 120px;
    border-radius: 20px;
    height: 35px;
    cursor: pointer;
    font-family: 'Nunito';
    font-size: 16px;
}

.btn-nav-secundarios:hover
{
    background-color: #4834d44f;
    border-radius: 50px;
    transition: .3s ease all;
}

.btn-nav-principal:hover
{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: .2s ease all;
}

@media screen and (max-width: 760px)
{
	nav
	{
		justify-content: center;
	}
}

@media screen and (max-width: 476px)
{
    .btn-nav-secundarios, .btn-nav-principal
    {
        font-size: 13px;
        width: 100px;
    }    
}

@media screen and (max-width: 415px)
{
    .btn-nav-secundarios, .btn-nav-principal
    {
        width: 80px;
    }

    .btn-nav-principal
    {
        padding: 1% 0;
    }
}


.container-proyectos
{
    height: 100%;
    margin: 0 10%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.img-sist
{
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 20px 0px #161616bf;
}

.img-logo-leng
{
    width: 100%;
}

figure
{
    display: flex;
    padding: 0 5%;
    margin: 0;
    height: calc(100% - 26px - 5%);
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1400px)
{
    .container-proyectos
    {
        margin: 0 5%;
    }

    figure
    {
        padding: 0 30px;
    }
}

@media screen and (max-width: 1219px)
{
    .container-proyectos
    {
        margin: 0;
    }
}

@media screen and (max-width: 1090px)
{
    .img-sist
    {
        width: 100%;
    }

    .link-repo
    {
        font-size: 15px;
    }
}

@media screen and (max-width: 815px)
{
    .container-sistema
    {
        width: 100vw;
    }

    .container-proyectos
    {
        display: flex;
        overflow-x: auto;
    }

    figure
    {
        margin: 0 10%;
    }
}

@media screen and (max-width: 430px)
{
    figure
    {
        margin: 0 2%;
    }
}

.link-repo
{
    cursor: pointer;
    margin-bottom: 10px;
    color: var(--font);
    text-decoration: none;
    background: var(--principal);
    border: none;
    border-radius: 4px;
    padding: 5px 12px;
    font-family: 'Nunito';
    width: auto;
}

.link-repo:hover
{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: .2s ease all;
}

.container-logo-leng
{
    width: 100%;
    display: flex;
    justify-content: center;
}

.img-logo-leng
{
	object-fit: contain;
}

.link
{
    cursor: pointer;
    margin-bottom: 10px;
    color: var(--font);
    text-decoration: none;
    background: var(--principal);
    border: none;
    border-radius: 4px;
    padding: 5px 12px;
    font-family: 'Nunito';
    width: auto;
}

.link:hover
{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: .2s ease all;
}
.container-sistema
{
    height: calc(100%);
    text-align: center;
    position: relative;
}

.container-sistema h3
{
    margin: 0;
}

.container-titulo-sist
{
    padding-top: 5%;
}

.container-links-sist
{
    text-align: center;
    border-right: 3px solid white;
    width: 120px;
    padding-right: 10px;
}

.container-img-logo-sist
{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3%;
    gap: 3%;
}

.container-img-logo-sist img
{
    max-width: 150px;
}

.container-info-sist
{
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    justify-content: center;
}

.img-sist-app
{
    max-height: 305px;  
    border-radius: 5px;
    box-shadow: 0px 0px 50px 0px #161616bf;
}

.num-1
{
    border-top-left-radius: 5px;
    background-color: #686de0;
}

.num-2
{
    border-top-right-radius: 5px;
    background-color: #30336b;
}

.num-3
{
    border-bottom-left-radius: 5px;
    background-color: #4834d4;
}

.num-4
{
    border-bottom-right-radius: 5px;
    background-color: #130f40;
}

.capa
{
    position: absolute;
    top: 0;
    width: 90%;
    height: 100%;
    padding: 0 5%;
    background: #171046c2;
    transition: all 400ms ease-out;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center
}

.container-sistema:hover > .capa 
{
    opacity: 1;
    visibility: visible;
}

.container-sistema:hover > .capa h3
{
    margin-top: 70px;
    margin-bottom: 15px;
}

.container-sistema .capa h3
{
    color: #fff;
    font-weight: 400;
    margin-bottom: 120px;
    transition: all 400ms ease-out;
    margin-top: 30px;
}

.container-sistema .capa p
{
    color: #fff;
    font-size: 15px;
    line-height: 1.5;
    width: 100%;
    max-width: 220px;
    margin: auto;
}

.container-habilidades-info
{
    text-align: center;
    margin-top: 30px;
}

.container-header-habilidades, .container-habilidades-por-aprender
{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 60px;
    margin-bottom: 50px;
}

.container-header-habilidades img, .container-habilidades-por-aprender img
{
	object-fit: contain;
    width: 140px;
}

.container-body-habilidades
{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15% 10%;
    gap: 15% 10%;
}

@media screen and (max-width: 710px)
{
    .container-header-habilidades img
    {
        object-fit: contain;
        width: 100px;
    }
}

@media screen and (max-width: 610px)
{
    .container-max
    {
        max-width: 300px;
    }

    .container-header-habilidades, .container-habilidades-por-aprender
    {
        
        margin: 0;
    }

    .container-header-habilidades img
    {
        width: 70px;
    }

    .container-habilidades
    {
        height: 190px;
    }
}
.container-sobre-mi-data
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    gap: 20px;
}

.container-sobre-mi
{
    height: 100vh;
}

.container-text-sobre-mi
{
    box-sizing: border-box;
}

.container-fondo-codigo
{
    border-radius: 10px;
    background-color: var(--font2);
    height:calc(100% - 20px);
    margin-top: 60px;
    margin-right: 65px;
}

.container-codigo
{
    position: absolute;
    top: 90px;
    left: 530px;
    box-shadow: 0 4px 30px rgb(0 0 0 / 50%);
    color: var(--font);
    height: 200px;
    background-color: #34383d;
    border-radius: 10px;
    width: 390px;
}

.container-codigo-color
{
    padding: 10px;
    padding-top: 0;
}

.codigo-color-principal
{
    color: var(--font2);
	-webkit-animation: colorText 5s ease-out infinite, nudgeText 5s ease-out infinite;
	        animation: colorText 5s ease-out infinite, nudgeText 5s ease-out infinite;
}

.codigo-color-secundario
{
    color: var(--font2);
	-webkit-animation: colorText2 5s ease-out infinite, nudgeText 5s ease-out infinite;
	        animation: colorText2 5s ease-out infinite, nudgeText 5s ease-out infinite;
}

.codigo-color-terciario
{
    color: var(--font2);
	-webkit-animation: colorText3 5s ease-out infinite, nudgeText 5s ease-out infinite;
	        animation: colorText3 5s ease-out infinite, nudgeText 5s ease-out infinite;
}

.tab
{
    padding-left: 2rem;
}

.escritura-codigo
{
    width: 280px;
    font-size: 13px;
    margin: 0;
    -webkit-animation: escribir 5s steps(39), parpadeo .5s step-end infinite alternate;
            animation: escribir 5s steps(39), parpadeo .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
}

.op-2
{
    top: 270px;
    left: 510px;
}

.op-3
{
    top: 460px;
    left: 530px;
}

@media screen and (max-width: 1024px)
{
    .container-max
    {
        max-width: 890px;
    }

    .container-codigo
    {
        left: 470px;
    }

    .op-2
    {
        left: 490px;
    }
}

@media screen and (max-width: 922px)
{
    .container-max
    {
        max-width: 800px;
    }

    .container-codigo
    {
        left: 400px;
        width: 340px;
    }

    .op-2
    {
        left: 420px;
    }
}

@media screen and (max-width: 810px)
{
    .container-text-sobre-mi p
    {
        width: 300px;
    } 

    .container-max
    {
        max-width: 700px;
    }

    .container-codigo
    {
        left: 370px;
        top: 80px;
        width: 300px;
        height: 180px;
    }

    .op-2
    {
        top: 220px;
        left: 390px;
    }

    .op-3
    {
        top: 350px;
    }
}

@media screen and (max-width: 810px)
{
    .container-max
    {
        max-width: 600px;
    }
    
    .container-codigo
    {
        left: 330px;
        top: 80px;
        width: 240px;
        height: 180px;
    }

    .container-codigo-color
    {
        font-size: 10px;
    }

    .op-2
    {
        top: 220px;
        left: 350px;
    }

    .op-3
    {
        top: 350px;
    }
}

@media screen and (max-width: 710px)
{
    .container-max
    {
        max-width: 400px;
    }

    .container-sobre-mi-data
    {
        display: block;
    }

    .container-fondo-codigo
    {
        margin: 0;
    }

    .container-text-sobre-mi p
    {
        width: 100%;
    }

    .container-codigo
    {
        left: 0px;
        top: 400px;
        width: 240px;
        height: 180px;
    }

    .op-2
    {
        top: 450px;
        left: 50px;
    }

    .op-3
    {
        top: 520px;
    }
}

@media screen and (max-width: 610px)
{
    .container-max
    {
        max-width: 300px;
    }
}
