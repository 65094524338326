
.container-proyectos
{
    height: 100%;
    margin: 0 10%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.img-sist
{
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 20px 0px #161616bf;
}

.img-logo-leng
{
    width: 100%;
}

figure
{
    display: flex;
    padding: 0 5%;
    margin: 0;
    height: calc(100% - 26px - 5%);
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1400px)
{
    .container-proyectos
    {
        margin: 0 5%;
    }

    figure
    {
        padding: 0 30px;
    }
}

@media screen and (max-width: 1219px)
{
    .container-proyectos
    {
        margin: 0;
    }
}

@media screen and (max-width: 1090px)
{
    .img-sist
    {
        width: 100%;
    }

    .link-repo
    {
        font-size: 15px;
    }
}

@media screen and (max-width: 815px)
{
    .container-sistema
    {
        width: 100vw;
    }

    .container-proyectos
    {
        display: flex;
        overflow-x: auto;
    }

    figure
    {
        margin: 0 10%;
    }
}

@media screen and (max-width: 430px)
{
    figure
    {
        margin: 0 2%;
    }
}